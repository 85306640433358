<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types'

const model = defineModel<boolean>()

const props = defineProps<{
  title?: string
  description?: string
  onSubmit: (event: FormSubmitEvent<{ id: string, [k: string]: unknown }[]>) => void
}>()
</script>

<template>
  <UDashboardModal
    v-model="model"
    :title="title ?? 'Eliminar registro(s)'"
    :description="description ?? 'Esta acción es irreversible. ¿Estás seguro que deseas proceder?'"
    prevent-close
  >
    <template #footer>
      <UButton
        color="red"
        label="Eliminar"
        @click="onSubmit"
      />
      <UButton
        color="white"
        label="Cancelar"
        @click="model = false"
      />
    </template>
  </UDashboardModal>
</template>
